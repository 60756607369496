import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ModalWrapper = ({
  show,
  size,
  heading,
  clickHandler,
  closeHandler,
  submitText,
  children,
}) => {
  const [smShow, setSmShow] = useState(show);

  return (
    <>
      <Modal
        size={size ? size : "sm"}
        centered
        show={show}
        onHide={closeHandler}
        aria-labelledby="example-modal-sizes-title-sm"
        style={{ zIndex: "111111111111111111111111111111111" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body> {children} </Modal.Body>
        <Modal.Footer>
          {closeHandler && (
            <Button variant="secondary" onClick={closeHandler} centered>
              Close
            </Button>
          )}
          {clickHandler && (
            <Button variant="primary" onClick={clickHandler} centered>
              {submitText ? submitText : "Submit"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalWrapper;
